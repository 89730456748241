import React, {useEffect, useState } from 'react'
import { DottedBackgroundSmall } from '../DottedBackgroundSmall'
import cls from './contact.module.scss'
import {motion, useAnimation} from 'framer-motion'
import {useInView} from 'react-intersection-observer'
import emailjs from 'emailjs-com';
import Axios from "axios";

let ContactForm =( {setSectionValue}) => {
  const animation = useAnimation()
  const {ref, inView} = useInView({
    threshold: 0.8,
  })
  useEffect(() => {
    if (inView) {
      setSectionValue('04')
      animation.start('visible')
    } else {
      animation.start('hide')
    }
  }, [animation, inView, setSectionValue])
  const variants = {
    visible: {
      opacity: 1,
    },
    hide: {
      opacity: 0,
    },
  }
  const [formValues, setFormValues] = useState({
    full_name: '',
    email: '',
    message: '',
  })
  const handleSubmit = (e) => {
    e.preventDefault();
    Axios.post('http://159.89.30.129/request/', {
      full_name: formValues.full_name,
      email: formValues.email,
      message: formValues.message
    }).then(res => {
      /*console.log(res.data)*/
    })

/*    emailjs.sendForm('service_id', 'template_id', e.target, 'user_id')
        .then((result) => {
          console.log(result.text);
        }, (error) => {
          console.log(error.text);
        });*/
  }
  const handleChange = (e) => {
    setFormValues({ ...formValues, [e.target.name]: e.target.value })
    /*console.log(formValues)*/
  }
  return (
    <div ref={ref} className={cls.container} id='contacts'>
      {/* <DottedBackgroundSmall />
      <DottedBackgroundSmall /> */}
      <div className='wrapper'>
        <div className={cls.inner}>
          <form action='/' method='post' onSubmit={handleSubmit}>
            <p className={cls.title}>Contact us</p>
            <div className={cls.input}>
              <label htmlFor='fullName'>Full Name:</label>
              <input
                id='full_name'
                value={formValues.full_name}
                onChange={handleChange}
                name='full_name'
                type='text'
                placeholder='Sam Smith'
              />
            </div>
            <div className={cls.input}>
              <label htmlFor='email'>Email:</label>
              <input
                id='email'
                value={formValues.email}
                onChange={handleChange}
                name='email'
                placeholder='samsmith@gmail.com'
                type='email'
              />
            </div>
            <div className={cls.input}>
              <label htmlFor='email'>Your message:</label>
              <textarea
                id='message'
                rows='4'
                value={formValues.message}
                onChange={handleChange}
                name='message'
                placeholder='Type your message...'
              ></textarea>
            </div>
            <div className={cls.actions}>
              <button className='btn'>Submit</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export default ContactForm
