export const DottedBackgroundLarge = () => (
  <svg
    width='502'
    height='502'
    viewBox='0 0 502 502'
    fill='none'
    className='dotted'
    xmlns='http://www.w3.org/2000/svg'
  >
    <g opacity='0.24'>
      <rect width='12.55' height='12.55' fill='#C4C4C4' />
      <rect x='54.3834' width='12.55' height='12.55' fill='#C4C4C4' />
      <rect x='108.767' width='12.55' height='12.55' fill='#C4C4C4' />
      <rect x='163.15' width='12.55' height='12.55' fill='#C4C4C4' />
      <rect x='217.533' width='12.55' height='12.55' fill='#C4C4C4' />
      <rect x='271.917' width='12.55' height='12.55' fill='#C4C4C4' />
      <rect x='326.3' width='12.55' height='12.55' fill='#C4C4C4' />
      <rect x='380.683' width='12.55' height='12.55' fill='#C4C4C4' />
      <rect x='435.067' width='12.55' height='12.55' fill='#C4C4C4' />
      <rect x='489.45' width='12.55' height='12.55' fill='#C4C4C4' />
      <rect y='54.3833' width='12.55' height='12.55' fill='#C4C4C4' />
      <rect
        x='54.3834'
        y='54.3833'
        width='12.55'
        height='12.55'
        fill='#C4C4C4'
      />
      <rect
        x='108.767'
        y='54.3833'
        width='12.55'
        height='12.55'
        fill='#C4C4C4'
      />
      <rect
        x='163.15'
        y='54.3833'
        width='12.55'
        height='12.55'
        fill='#C4C4C4'
      />
      <rect
        x='217.533'
        y='54.3833'
        width='12.55'
        height='12.55'
        fill='#C4C4C4'
      />
      <rect
        x='271.917'
        y='54.3833'
        width='12.55'
        height='12.55'
        fill='#C4C4C4'
      />
      <rect x='326.3' y='54.3833' width='12.55' height='12.55' fill='#C4C4C4' />
      <rect
        x='380.683'
        y='54.3833'
        width='12.55'
        height='12.55'
        fill='#C4C4C4'
      />
      <rect
        x='435.067'
        y='54.3833'
        width='12.55'
        height='12.55'
        fill='#C4C4C4'
      />
      <rect
        x='489.45'
        y='54.3833'
        width='12.55'
        height='12.55'
        fill='#C4C4C4'
      />
      <rect y='108.767' width='12.55' height='12.55' fill='#C4C4C4' />
      <rect
        x='54.3834'
        y='108.767'
        width='12.55'
        height='12.55'
        fill='#C4C4C4'
      />
      <rect
        x='108.767'
        y='108.767'
        width='12.55'
        height='12.55'
        fill='#C4C4C4'
      />
      <rect
        x='163.15'
        y='108.767'
        width='12.55'
        height='12.55'
        fill='#C4C4C4'
      />
      <rect
        x='217.533'
        y='108.767'
        width='12.55'
        height='12.55'
        fill='#C4C4C4'
      />
      <rect
        x='271.917'
        y='108.767'
        width='12.55'
        height='12.55'
        fill='#C4C4C4'
      />
      <rect x='326.3' y='108.767' width='12.55' height='12.55' fill='#C4C4C4' />
      <rect
        x='380.683'
        y='108.767'
        width='12.55'
        height='12.55'
        fill='#C4C4C4'
      />
      <rect
        x='435.067'
        y='108.767'
        width='12.55'
        height='12.55'
        fill='#C4C4C4'
      />
      <rect
        x='489.45'
        y='108.767'
        width='12.55'
        height='12.55'
        fill='#C4C4C4'
      />
      <rect y='163.15' width='12.55' height='12.55' fill='#C4C4C4' />
      <rect
        x='54.3834'
        y='163.15'
        width='12.55'
        height='12.55'
        fill='#C4C4C4'
      />
      <rect
        x='108.767'
        y='163.15'
        width='12.55'
        height='12.55'
        fill='#C4C4C4'
      />
      <rect x='163.15' y='163.15' width='12.55' height='12.55' fill='#C4C4C4' />
      <rect
        x='217.533'
        y='163.15'
        width='12.55'
        height='12.55'
        fill='#C4C4C4'
      />
      <rect
        x='271.917'
        y='163.15'
        width='12.55'
        height='12.55'
        fill='#C4C4C4'
      />
      <rect x='326.3' y='163.15' width='12.55' height='12.55' fill='#C4C4C4' />
      <rect
        x='380.683'
        y='163.15'
        width='12.55'
        height='12.55'
        fill='#C4C4C4'
      />
      <rect
        x='435.067'
        y='163.15'
        width='12.55'
        height='12.55'
        fill='#C4C4C4'
      />
      <rect x='489.45' y='163.15' width='12.55' height='12.55' fill='#C4C4C4' />
      <rect y='217.533' width='12.55' height='12.55' fill='#C4C4C4' />
      <rect
        x='54.3834'
        y='217.533'
        width='12.55'
        height='12.55'
        fill='#C4C4C4'
      />
      <rect
        x='108.767'
        y='217.533'
        width='12.55'
        height='12.55'
        fill='#C4C4C4'
      />
      <rect
        x='163.15'
        y='217.533'
        width='12.55'
        height='12.55'
        fill='#C4C4C4'
      />
      <rect
        x='217.533'
        y='217.533'
        width='12.55'
        height='12.55'
        fill='#C4C4C4'
      />
      <rect
        x='271.917'
        y='217.533'
        width='12.55'
        height='12.55'
        fill='#C4C4C4'
      />
      <rect x='326.3' y='217.533' width='12.55' height='12.55' fill='#C4C4C4' />
      <rect
        x='380.683'
        y='217.533'
        width='12.55'
        height='12.55'
        fill='#C4C4C4'
      />
      <rect
        x='435.067'
        y='217.533'
        width='12.55'
        height='12.55'
        fill='#C4C4C4'
      />
      <rect
        x='489.45'
        y='217.533'
        width='12.55'
        height='12.55'
        fill='#C4C4C4'
      />
      <rect y='271.917' width='12.55' height='12.55' fill='#C4C4C4' />
      <rect
        x='54.3834'
        y='271.917'
        width='12.55'
        height='12.55'
        fill='#C4C4C4'
      />
      <rect
        x='108.767'
        y='271.917'
        width='12.55'
        height='12.55'
        fill='#C4C4C4'
      />
      <rect
        x='163.15'
        y='271.917'
        width='12.55'
        height='12.55'
        fill='#C4C4C4'
      />
      <rect
        x='217.533'
        y='271.917'
        width='12.55'
        height='12.55'
        fill='#C4C4C4'
      />
      <rect
        x='271.917'
        y='271.917'
        width='12.55'
        height='12.55'
        fill='#C4C4C4'
      />
      <rect x='326.3' y='271.917' width='12.55' height='12.55' fill='#C4C4C4' />
      <rect
        x='380.683'
        y='271.917'
        width='12.55'
        height='12.55'
        fill='#C4C4C4'
      />
      <rect
        x='435.067'
        y='271.917'
        width='12.55'
        height='12.55'
        fill='#C4C4C4'
      />
      <rect
        x='489.45'
        y='271.917'
        width='12.55'
        height='12.55'
        fill='#C4C4C4'
      />
      <rect y='326.3' width='12.55' height='12.55' fill='#C4C4C4' />
      <rect x='54.3834' y='326.3' width='12.55' height='12.55' fill='#C4C4C4' />
      <rect x='108.767' y='326.3' width='12.55' height='12.55' fill='#C4C4C4' />
      <rect x='163.15' y='326.3' width='12.55' height='12.55' fill='#C4C4C4' />
      <rect x='217.533' y='326.3' width='12.55' height='12.55' fill='#C4C4C4' />
      <rect x='271.917' y='326.3' width='12.55' height='12.55' fill='#C4C4C4' />
      <rect x='326.3' y='326.3' width='12.55' height='12.55' fill='#C4C4C4' />
      <rect x='380.683' y='326.3' width='12.55' height='12.55' fill='#C4C4C4' />
      <rect x='435.067' y='326.3' width='12.55' height='12.55' fill='#C4C4C4' />
      <rect x='489.45' y='326.3' width='12.55' height='12.55' fill='#C4C4C4' />
      <rect y='380.683' width='12.55' height='12.55' fill='#C4C4C4' />
      <rect
        x='54.3834'
        y='380.683'
        width='12.55'
        height='12.55'
        fill='#C4C4C4'
      />
      <rect
        x='108.767'
        y='380.683'
        width='12.55'
        height='12.55'
        fill='#C4C4C4'
      />
      <rect
        x='163.15'
        y='380.683'
        width='12.55'
        height='12.55'
        fill='#C4C4C4'
      />
      <rect
        x='217.533'
        y='380.683'
        width='12.55'
        height='12.55'
        fill='#C4C4C4'
      />
      <rect
        x='271.917'
        y='380.683'
        width='12.55'
        height='12.55'
        fill='#C4C4C4'
      />
      <rect x='326.3' y='380.683' width='12.55' height='12.55' fill='#C4C4C4' />
      <rect
        x='380.683'
        y='380.683'
        width='12.55'
        height='12.55'
        fill='#C4C4C4'
      />
      <rect
        x='435.067'
        y='380.683'
        width='12.55'
        height='12.55'
        fill='#C4C4C4'
      />
      <rect
        x='489.45'
        y='380.683'
        width='12.55'
        height='12.55'
        fill='#C4C4C4'
      />
      <rect y='435.067' width='12.55' height='12.55' fill='#C4C4C4' />
      <rect
        x='54.3834'
        y='435.067'
        width='12.55'
        height='12.55'
        fill='#C4C4C4'
      />
      <rect
        x='108.767'
        y='435.067'
        width='12.55'
        height='12.55'
        fill='#C4C4C4'
      />
      <rect
        x='163.15'
        y='435.067'
        width='12.55'
        height='12.55'
        fill='#C4C4C4'
      />
      <rect
        x='217.533'
        y='435.067'
        width='12.55'
        height='12.55'
        fill='#C4C4C4'
      />
      <rect
        x='271.917'
        y='435.067'
        width='12.55'
        height='12.55'
        fill='#C4C4C4'
      />
      <rect x='326.3' y='435.067' width='12.55' height='12.55' fill='#C4C4C4' />
      <rect
        x='380.683'
        y='435.067'
        width='12.55'
        height='12.55'
        fill='#C4C4C4'
      />
      <rect
        x='435.067'
        y='435.067'
        width='12.55'
        height='12.55'
        fill='#C4C4C4'
      />
      <rect
        x='489.45'
        y='435.067'
        width='12.55'
        height='12.55'
        fill='#C4C4C4'
      />
      <rect y='489.45' width='12.55' height='12.55' fill='#C4C4C4' />
      <rect
        x='54.3834'
        y='489.45'
        width='12.55'
        height='12.55'
        fill='#C4C4C4'
      />
      <rect
        x='108.767'
        y='489.45'
        width='12.55'
        height='12.55'
        fill='#C4C4C4'
      />
      <rect x='163.15' y='489.45' width='12.55' height='12.55' fill='#C4C4C4' />
      <rect
        x='217.533'
        y='489.45'
        width='12.55'
        height='12.55'
        fill='#C4C4C4'
      />
      <rect
        x='271.917'
        y='489.45'
        width='12.55'
        height='12.55'
        fill='#C4C4C4'
      />
      <rect x='326.3' y='489.45' width='12.55' height='12.55' fill='#C4C4C4' />
      <rect
        x='380.683'
        y='489.45'
        width='12.55'
        height='12.55'
        fill='#C4C4C4'
      />
      <rect
        x='435.067'
        y='489.45'
        width='12.55'
        height='12.55'
        fill='#C4C4C4'
      />
      <rect x='489.45' y='489.45' width='12.55' height='12.55' fill='#C4C4C4' />
    </g>
  </svg>
)
